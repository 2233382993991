<script setup lang="ts">
import { Category } from '~/types'
import HeaderCategory from './HeaderCategory.vue'

const emit = defineEmits(['change'])

const router = useRouter()
const { category } = storeToRefs(useCategoryStore())

const onNavigateTo = (value: string) => {
  router.push(`/category/${value}`);
  emit('change')
}
</script>

<template>
  <div class="flex flex-col md:flex-row items-center gap-8 md:gap-2" v-bind="$attrs">
    <HeaderCategory :icon="Category.ENERGY" label="Luce e Gas" :active="category === Category.ENERGY"
      @click="onNavigateTo(Category.ENERGY)" />
    <HeaderCategory :icon="Category.INSURANCE" label="Assicurazioni" :active="category === Category.INSURANCE"
      @click="onNavigateTo(Category.INSURANCE)" />
    <HeaderCategory :icon="Category.TELCO" label="Telefonia" :active="category === Category.TELCO"
      @click="onNavigateTo(Category.TELCO)" />
  </div>
</template>